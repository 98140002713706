import request from '@/auth/jwt/request'

/**
 * @description 文件分类 -- 获取文件分类的子分类
 * @param {Object} param params {Object} 传值参数
 */
export const fileCategoryChildrenApi = params => { return request('fileCategory/children', 'get', params) }

/**
 * @description 文件分类 -- 获取文件分类下拉列表
 * @param {Object} param params {Object} 传值参数
 */
export const fileCategorySelectApi = params => { return request('fileCategory/select', 'get', params) }

/**
 * @description 文件分类 -- 增加修改文件分类
 * id = 0 新增
 * @param {Object} param params {Object} 传值参数
 */
export const fileCategorySaveApi = data => { return request('fileCategory/save', 'post', {}, data) }

/**
 * @description 文件分类 -- 下拉顶级文件分类
 * @param {Object} param params {Object} 传值参数
 */
export const fileCategoryTreeApi = () => { return request('fileCategory/tree', 'get') }

/**
 * @description 文件分类 -- 删除文件分类
 * { id = x }
 */
export const fileCategoryDeleteApi = data => { return request('fileCategory/delete', 'delete', {}, data) }