// 树形数组 查询父节点
const getIds = (list, id) => {
  for (let i = 0; i < list.length; i += 1) {
    if (list[i].value === id) {
      return [list[i].value]
    }
    if (list[i].children) {
      const node = getIds(list[i].children, id)
      if (node !== undefined) {
        return node.concat(list[i].value)
      }
    }
  }
}

export const getParentIds = (list, id) => {
  const arrNew = getIds(list, id).reverse()
  arrNew.pop()
  return arrNew.length ? arrNew : [0]
}

// 对象数组去重复
export const arrDistinctByProp = (arr, prop) => {
  return arr.filter((item, index, self) => { return self.findIndex(el => el[prop] === item[prop]) === index })
}

// 对象数组 抽取对象某一个属性形成新的 数组
export const distinctArrToPropArr = (arr, prop) => {
  return arr.map(item => { return item[prop] })
}

// 对象数组删除某个对象中指定属性的对象
export const distinctArrDelFromProp = (arr, prop, val) => {
  return arr.splice(arr.indexOf(arr.find(item => { return item[prop] === val })), 1)
}

// 树形结构遍历增加属性
export const treeSet = (arr, property, val) => {
  arr.forEach(item => {
    item[property] = val
    if (item.children && item.children.length) {
      treeSet(item.children, property, val)
    }
  })
  return arr
}
