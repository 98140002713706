<template>
  <b-modal
    id="select-modal"
    :visible="shallShowModal"
    title="图片选择"
    centered
    footer-class="d-flex justify-content-between"
    no-close-on-backdrop
    @change="(val) => $emit('update:shall-show-modal', val)"
  >
    <!-- Modal Header -->
    <template #modal-header>
      <h5 class="modal-title"> 图片选择 </h5>
      <div class="modal-actions">
        <feather-icon icon="XIcon" class="ml-1 cursor-pointer" @click="$emit('update:shall-show-modal', false)" />
      </div>
    </template>

    <!-- Modal Footer -->
    <template #modal-footer>
      <div>
        <b-button
          variant="outline-secondary"
          class="cursor-pointer"
          @click="$emit('update:shall-show-modal', false)"
        >
          取消
        </b-button>
      </div>

      <div>
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="primary"
          @click="onSubmit"
        >
          确定
        </b-button>
      </div>
    </template>

    <div class="image-app">
      <file-left-sidebar
        :class="{'show': mqShallShowLeftSidebar}"
        :file-category="fileCategory"
        @category-array-change="categoryArrayChange"
        @close-left-sidebar="mqShallShowLeftSidebar = false"
      />

      <div class="content-right">
        <div
          class="body-content-overlay"
          :class="{'show': mqShallShowLeftSidebar}"
          @click="mqShallShowLeftSidebar = false"
        />
        <div class="file-app-list">
          <!-- App Searchbar Header -->
          <div class="app-fixed-search d-flex align-items-center">

            <!-- Toggler -->
            <div class="sidebar-toggle d-block d-lg-none ml-1">
              <feather-icon icon="MenuIcon" size="21" class="cursor-pointer" @click="mqShallShowLeftSidebar = true" />
            </div>

            <!-- Searchbar -->
            <div class="d-flex align-content-center justify-content-between w-100">
              <b-input-group class="input-group-merge">
                <b-input-group-prepend is-text>
                  <feather-icon icon="SearchIcon" class="text-muted" />
                </b-input-group-prepend>
                <b-form-input v-model="searchParams.name" placeholder="文件名称" />
              </b-input-group>
            </div>

            <!-- 批量操作 -->
            <div class="d-flex align-items-center mr-1">
              <feather-icon icon="CheckSquareIcon" @click="SelectAllClick" size="17" class="text-body cursor-pointer mr-1"/>

              <feather-icon icon="RotateCwIcon" @click="cancelAllClick" size="17" class="text-body cursor-pointer mr-1"/>

              <b-dropdown variant="link" no-caret toggle-class="p-0" right>
                <template #button-content>
                  <feather-icon icon="UploadIcon" size="17" class="align-middle text-body"/>
                </template>
                <b-dropdown-item v-for="item in uploadLabel.filter(it => it.type == 1)" :key="item.value"
                  @click="upLoadClick(item.value)">
                  <feather-icon :icon="item.icon" />
                  <span class="align-middle ml-50">{{ item.name }}</span>
                </b-dropdown-item>
              </b-dropdown>

              <Upload ref="refInputUpload"
                :headers="header"
                :action="fileUrl"
                :data="uploadData"
                :show-upload-list="false"
                :on-success="handleSuccess"
                :format="formatFile"
                :accept="acceptFile"
                :max-size="maxSize"
                :on-format-error="handleFormatError"
                :on-exceeded-size="handleMaxSize"
                :before-upload="handleBeforeUpload"
                :multiple="isMultiple"
                :name="isMultiple ? 'uploadFiles' :'uploadFile'"
                class="d-none" />
            </div>
          </div>

          <vue-perfect-scrollbar
            :settings="perfectScrollbarSettings"
            class="file-manage-list-wrapper scroll-area pt-75 px-1"
          >
            <section class="grid-view">
              <div
                v-for="file in fileArray"
                :key="file.id"
                class="file-card"
                :class="selected.includes(file) ? 'selectedd':''"
              >
                <b-form-checkbox-group id="checkbox-group" v-model="selected">
                  <div class="item-check"> <b-form-checkbox :checked="file.id" :value="file"></b-form-checkbox> </div>
                  <div class="item-img text-center">
                      <b-img
                        :alt="`${file.name}-${file.id}`"
                        fluid
                        class="card-img-top"
                        :src="file.path"
                        @error="(ev) => onHandleImgUrlError(ev, file)"
                      />
                  </div>
                  <div class="item-name text-center text-truncate font-weight-bolder"> {{ file.name }}</div>
                  <div class="item-description text-center"> {{ fileSize(file.size) }} </div>
                </b-form-checkbox-group>
              </div>
            </section>
            <div class="no-results" :class="fileArray.length === 0 ? 'show': ''">
              <h5>没有查找到文件</h5>
            </div>
          </vue-perfect-scrollbar>

          <div class="d-flex justify-content-between align-items-center px-2" style="height: 3rem">
            <span class="text-nowrap"> 共 {{ total }} 条记录 </span>
            <b-pagination v-model="searchParams.page" :total-rows="total" :per-page="searchParams.size" first-number last-number align="right" prev-class="prev-item" next-class="next-item" class="mb-0">
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { ref, watch, reactive, nextTick } from '@vue/composition-api'
import Ripple from 'vue-ripple-directive'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { fileListApi } from '@/api/system/files'
import { fileCategoryTreeApi } from '@/api/system/filesCate'
import { useResponsiveAppLeftSidebarVisibility } from '@core/comp-functions/ui/app'
import FileLeftSidebar from './FileLeftSidebar.vue'
import fileUpload from './fileUpload'
import fileFormatType from './fileType'

export default {
  directives: {
    Ripple,
  },
  components: {
    VuePerfectScrollbar,
    // App SFC
    FileLeftSidebar,
  },
  model: {
    prop: 'shallShowModal',
    event: 'update:shall-show-modal',
  },
  props: {
    shallShowModal: {
      type: Boolean,
      required: true,
    },
    type: {
      type: Number,
      required: true,
    },
    paramObject: {
      type: [String, Array, Object, Boolean, null],
      required: false,
      default: null
    }
  },
  setup(props, { root, emit }) {
    /* 必要组件加载区域 */
    // 权限组件 弹出确认组件
    const { $swal } = root
    // 消息提醒组件
    const toast = useToast()

    const perfectScrollbarSettings = { maxScrollbarLength: 150 }

    // UI 呈现数据
    const uploadLabel = [
      { title: '新建目录', name: '目录', icon: 'FileTextIcon', value: 'category', type: 2 },
      { title: '上传图像', name: '图像', icon: 'ImageIcon', value: 'image', type: 1 },
    ]

    // 文件列表
    const fileArray = ref([])
    // 文件数量
    const total = ref(null)
    // 选中的文件
    const selected = ref([])
    // 查询条件
    const searchParams = reactive({ page: 1, size: 10, type: 'image', category_id: 0, name: '', tag: '' })
    // 左侧目录筛选条件 控制（较小情况下隐藏）
    const { mqShallShowLeftSidebar } = useResponsiveAppLeftSidebarVisibility()

    // 目录tree
    const fileCategory = ref([])
    // 目录获取 同时设置 子目录文件夹对象
    const getFileCategory = () => {
      fileCategoryTreeApi()
        .then(res => {
          fileCategory.value = res.data
          fileCategory.value.unshift({ id: 0, name: '根目录', pid: 0, selected: true })
        })
        .catch(err => {
          toast({
            component: ToastificationContent,
            props: { title: '获取文件目录失败', text: err.msg, icon: 'AlertTriangleIcon', variant: 'danger' },
          })
        })
    }
    // 获取文件列表
    const getFileList = () => {
      fileListApi(searchParams)
        .then(res => {
          fileArray.value = res.data.list
          total.value = res.data.total
          selected.value = []
        })
        .catch(err => {
          toast({
            component: ToastificationContent,
            props: { title: '获取文件列表失败', text: err.msg, icon: 'AlertTriangleIcon', variant: 'danger' },
          })
        })
    }
    // 查询条件变化时，触发查询
    watch(searchParams, () => { getFileList() })
    // 处理切换目录事件
    const categoryArrayChange = id => { searchParams.category_id = id }

    /*
     * 目录相关属性
     * 增删改事件
     */
    // 分类相关接口处理

    /* 文件操作接口 */
    const onSubmit = () => {
      if (selected.value.length === 0) {
        return $swal({
          title: '操作错误',
          text: '您没有选择任何文件!',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonText: '确定',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
      } else {
        emit('set-image', selected.value, props.type, props.paramObject)
        emit('update:shall-show-modal', false)
      }
    }

    /* 文件操作事件 */

    const SelectAllClick = () => { selected.value = fileArray.value }
    const cancelAllClick = () => { selected.value = [] }

    /* 文件上传类 配置项 */

    // 导入配置项参数
    const {
      header, fileUrl, uploadData, format, accept, maxSize, isMultiple,
      handleSuccess, handleFormatError, handleMaxSize, handleBeforeUpload,
    } = fileUpload(getFileList)

    // 设置渲染的DOM实例名，用于获取组件事件
    const refInputUpload = ref(null)

    // 处理不同类型文件对应的可上传类型及后缀格式名
    const formatFile = ref([])
    const acceptFile = ref('')

    // 处理DOM组件点击事件 触发文件上传组件获取文件信息，同时处理控制可上传文件类型等信息
    const upLoadClick = value => {
      formatFile.value = format.value[value]
      acceptFile.value = accept.value[value]
      uploadData.value.category_id = searchParams.category_id
      // 等待DOM刷新后触发事件
      nextTick(() => { refInputUpload.value.handleClick() })
    }

    watch(() => props.shallShowModal, val => {
      if (!val) {
        selected.value = []
      } else {
        getFileList()
        getFileCategory()
      }
    })


    // 计算文件大小
    const fileSize = size => {
      if (size >= 1024 * 1024 * 1024) {
        return (size / (1024 * 1024 * 1024)).toFixed(2) + ' GB'
      } else if (size >= 1024 * 1024) {
        return (size / (1024 * 1024)).toFixed(2) + ' MB'
      } else if (size >= 1024) {
        return (size / 1024).toFixed(2) + ' KB'
      } else return '1 KB'
    }
    // 计算文件大小
    const fileTypeName = name => {
      const arr = name.split('.')
      if (fileFormatType.includes(arr[arr.length - 1] + '.svg')) return arr[arr.length - 1] + '.svg'
      return 'weizhiwenjian.svg'
    }
    /* 图片加载失败 */
    const onHandleImgUrlError = (ev, file) => {
      // console.log("🚀 ~ file: index.vue ~ line 654 ~ setup ~ ev", ev)
      ev.target.src = require('@/assets/file-svg/'+ fileTypeName(file.name))
    }


    return {
      perfectScrollbarSettings,

      // Left Sidebar Responsive
      mqShallShowLeftSidebar,
      total,
      selected,
      uploadLabel,

      // 文件上传
      refInputUpload,
      formatFile,
      acceptFile,
      upLoadClick,

      header,
      fileUrl,
      uploadData,
      format,
      accept,
      maxSize,
      isMultiple,

      handleSuccess,
      handleFormatError,
      handleMaxSize,
      handleBeforeUpload,

      // 目录新增
      categoryArrayChange,
      fileArray,
      fileCategory,
      fileCategoryTreeApi,

      searchParams,

      SelectAllClick,
      cancelAllClick,
      onSubmit,

      /* 图片加载失败 */
      fileSize,
      onHandleImgUrlError,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-sweetalert.scss';
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/include';

#select-modal .modal-dialog { max-width: calc(1350px); }
#select-modal .modal-body { padding: 0; }

.image-app {
  height: 540px;
  // width: 1350px;
  .sidebar-left {
    float: left;
    height: inherit;
    .sidebar {
      height: inherit;
      .file-sidebar {
        width: 230px;
        height: inherit;
        display: flex;
        background-color: $white;
        border-top-left-radius: $card-border-radius;
        border-bottom-left-radius: $card-border-radius;
        transition: all 0.3s ease, background 0s;

        // Sidebar Menu
        .file-app-menu {
          height: inherit;
          width: 100%;
          z-index: 3;

          // Sidebar menu list items
          .sidebar-menu-list {
            position: relative;
            height: calc(100% - 3rem);
            // height: 100%;
          }
        }
      }
    }
  }
  // Modal editor height
  .ql-editor {
    padding-bottom: 0;
  }

  // Right content area common css starts
  .content-right {
    width: calc(100% - 230px);
    height: inherit;
    border-left: 1px solid $border-color;
    background-color: $white;

    .body-content-overlay {
      top: 0;
      bottom: 0;
      position: absolute;
      display: block;
      z-index: 4;
      visibility: hidden;
      opacity: 0;
      transition: all 0.3s ease;
    }

    .file-app-list {
      height: 100%;
    }
    // Todo Fixed search
    .app-fixed-search {
      padding: 0.35rem 0.35rem;
      border-bottom: 1px solid $border-color;
      border-top-right-radius: $border-radius;
      .input-group:focus-within {
        box-shadow: none;
      }
      input,
      .input-group-text {
        border: 0;
        background-color: transparent;
      }
    }

    // file list area
    .file-manage-list-wrapper {
      position: relative;
      height: 450px;
      background-color: $body-bg;
      border-radius: 0;
      // Grid View Specific style
      .grid-view {
        display: grid;
        grid-template-columns: repeat(auto-fill, 14.7rem);
        column-gap: 1rem;

        .file-card {
          overflow: hidden;
          background-color: $white;
          width: 14.7rem;
          height: 14.7rem;
          position: relative;
          border-radius: 0.375rem;
          border-color: transparent;
          margin-bottom: 1rem;
          padding: 0.9rem 1rem 0.75rem 1rem;

          &:hover {
            transform: translateY(-5px);
            box-shadow: 0 4px 25px 0 rgba($black, 0.25);
          }

          // Item Image
          .item-check {
            position: absolute;
            top: 0;
            left: 0;
            margin-top: 0.5rem;
            margin-left: 0.5rem;
          }

          .item-img {
            display: flex;
            justify-content: center;
            align-items: center;
            overflow: hidden;
            height: 140px;
            img {
              width: 100%;
              // height: 100%;
            }
          }

          .item-name {
            margin-top: 0.1rem;
          }

          // Item Description
          .item-description {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            margin-top: 0.125rem;
          }
          
        }
        .file-card.selectedd {
          border: 1px solid $primary;
        }
      }
      .no-results {
          display: none;
          padding: 1.5rem;
          text-align: center;
        }
      .no-results.show {
        display: block;
      }
    }
  }
}
@include media-breakpoint-down(md) {
  .image-app {
    .sidebar-left {
      .file-sidebar {
        transform: translateX(-110%);
        transition: all 0.3s ease-in-out;
        left: 0;
        position: absolute;
        z-index: 5;
        border-top-left-radius: $border-radius-sm;
        border-bottom-left-radius: $border-radius-sm;
      }
      &.show {
        .file-sidebar {
          transform: translateX(0%);
          transition: all 0.3s ease;
        }
      }
    }
    .content-right {
      width: 100%;
      border-left: 0;
      .app-fixed-search {
        border-top-left-radius: $border-radius;
      }

      .body-content-overlay {
        left: 0;
        right: 0;
      }

      .body-content-overlay.show {
          visibility: visible;
          transition: all 0.3s ease;
          opacity: 1;
          background-color: rgba(34, 41, 47, 0.2);
          border-radius: 0.1785rem;
      }
    }
  }
}

.dark-layout {
  .image-app  {
    .sidebar-left {
      .file-sidebar {
        background-color: $theme-dark-card-bg !important;
      }
    }
    .content-right,
    .content-body {
      background-color: $theme-dark-card-bg !important;
      .file-manage-list-wrapper {
        background-color: $theme-dark-body-bg !important;
        .grid-view .file-card {
          background-color: $theme-dark-card-bg !important;
          color: $theme-dark-body-color;
        }
      }
    }
    .content-right,
    .app-fixed-search {
      background-color: $theme-dark-card-bg !important;
      border-color: $theme-dark-border-color !important;
    }
    .content-right {
      border-color: $theme-dark-border-color !important;
    }
  }
}
</style>
