<template>
  <div class="sidebar-left">
    <div class="sidebar">
      <div class="sidebar-content file-sidebar">
        <div class="file-app-menu">
          <!-- 目录 -->
          <div class="px-2 d-flex justify-content-center align-items-center pt-1" style="height: 3rem">
            <h6 class="section-label" style="font-size: 1rem; text-align: center;"> 存储目录 </h6>
          </div>

          <vue-perfect-scrollbar
            :settings="perfectScrollbarSettings"
            class="sidebar-menu-list scroll-area"
          >
            <div class="d-flex align-items-center cursor-pointer my-1 ml-1">
              <Tree :data="fileCategory"
                :render="renderContent"
                @on-select-change="nodeClick"
              >
              </Tree>
            </div>
          </vue-perfect-scrollbar>
        </div>
      </div>
    </div>
</div>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import Ripple from 'vue-ripple-directive'

export default {
  directives: {
    Ripple,
  },
  components: {
    VuePerfectScrollbar,
  },
  props: {
    fileCategory: {
      type: Array,
      required: true,
    },
  },
  setup(props, { emit }) {
    const perfectScrollbarSettings = { maxScrollbarLength: 60 }

    // 目录点击 处理 文件子目录及更改单前目录ID
    const nodeClick = (treeNode, node) => {
      emit('category-array-change', node.id, node.children)
    }

    // 存储目录插入文件夹 使用iview 的tree 控件 使用render函数修改
    const renderContent = (h, { data }) => {
      return h('span', {
        style: { display: 'inline-block', width: '100%', padding: '0.25rem' },
      },
      [
        h('span', [
          h('feather-icon', {
            props: { icon: 'FolderIcon' },
            class: 'align-text-bottom line-height-1',
            style: { marginRight: '8px', width: '18px', height: '18px' }
          }),
          h('span', data.name)
        ])
      ])
    }

    return {
      perfectScrollbarSettings,
      renderContent,

      nodeClick,
    }
  },
}
</script>

<style lang="scss">
.ivu-tree-arrow {
  margin: 0.35rem 0;
  padding: 0.25rem 0;
}
.ivu-tree-title {
  margin: 0.35rem 0;
  }
</style>
