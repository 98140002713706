<template>
<div>
  <b-row class="d-flex justify-content-between">
    <!-- 左侧按钮区 -->
    <b-col cols="auto" class="d-flex" v-if="buttonLeftOptions.length">
      <section v-for="(item,index) in 
        buttonLeftOptions.slice(0, leftNum)" :key='index'>
        <b-button
          v-if="$can(item.acticon, item.subject)"
          :disabled="item.disabled"
          :variant="item.variant ? item.variant: 'outline-primary'"
          class="mb-1 mr-50"
          :class="btnIconLeft ? 'btn-icon': null"
          @click="buttonEvent(item.event)"
        >
          <feather-icon v-if="item.icon && btnIconLeft" :icon="item.icon" />
          <span class="ml-50" v-if="!btnIconLeft">{{ item.label }}</span>
        </b-button>
      </section>
      <b-dropdown
        class="mb-1"
        v-if="buttonLeftOptions.length > leftNum"
        :no-caret="btnIconLeft"
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        :variant="btnIconLeft ? 'link': 'outline-primary'"
        :toggle-class="btnIconLeft ? 'p-0': null"
      >
        <template #button-content>
          <b-button
            v-if="btnIconLeft"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-primary"
            class="btn-icon"
          >
            <feather-icon icon="ListIcon" />
          </b-button>
          <span v-else>更多</span>
        </template>

        <section v-for="(item,index) in 
          buttonLeftOptions.slice(leftNum)" :key='index' @click="buttonEvent(item.event)">
          <b-dropdown-item :variant="item.variant ? item.variant: 'outline-primary'" :disabled="item.disabled">
            <feather-icon v-if="item.icon" :icon="item.icon" class="mr-50" />
            {{ item.label }}
          </b-dropdown-item>
        </section>
      </b-dropdown>
    </b-col>
    <!-- 右侧按钮区 -->
    <b-col cols="auto" class="d-flex flex-row-reverse flex-grow-1" v-if="buttonRightOptions.length">
      <section v-for="(item,index) in 
        buttonRightOptions.slice(0, rightNum)" :key='index'>
        <b-button
          v-if="$can(item.acticon, item.subject)"
          :disabled="item.disabled"
          :variant="item.variant ? item.variant: 'outline-primary'"
          class="mb-1 ml-50"
          :class="btnIconRight ? 'btn-icon': null"
          @click="buttonEvent(item.event)"
        >
          <feather-icon v-if="item.icon && !btnIconRight" :icon="item.icon" />
          <span class="ml-50" v-if="!btnIconRight">{{ item.label }}</span>
        </b-button>
      </section>
      <b-dropdown
        v-if="buttonRightOptions.length > rightNum"
        class="mb-1"
        :no-caret="btnIconRight"
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        :variant="btnIconRight ? 'link': 'outline-primary'"
        :toggle-class="btnIconRight ? 'p-0': null"
      >
        <template #button-content>
          <b-button
            v-if="btnIconRight"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-primary"
            class="btn-icon"
          >
            <feather-icon icon="ListIcon" />
          </b-button>
          <span v-else>更多</span>
        </template>

        <section
          v-for="(item,index) in buttonRightOptions.slice(rightNum)" 
          :key='index'
          @click="buttonEvent(item.event)"
        >
          <b-dropdown-item
            :variant="item.variant ? item.variant: 'outline-primary'"
            :disabled="item.disabled"
          >
            <feather-icon v-if="item.icon" :icon="item.icon" class="mr-50" />
            {{ item.label }}
          </b-dropdown-item>
        </section>
      </b-dropdown>
    </b-col>
  </b-row>
</div>
</template>

<script>
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import { ref } from '@vue/composition-api'

export default {
  directives: {
    Ripple,
  },
  components: {
    vSelect,
  },
  props: {
    buttonLeftOptions: {
      type: Array,
      required: true,
    },
    buttonRightOptions: {
      type: Array,
      required: true,
    },
    leftNum: {
      type: Number,
      default: 2,
      required: false,
    },
    rightNum: {
      type: Number,
      default: 2,
      required: false,
    },
    btnIconLeft: {
      type: [Boolean, null],
      default: true,
      required: false,
    },
    btnIconRight: {
      type: [Boolean, null],
      default: false,
      required: false,
    },
  },

  setup(props, { emit }) {
    /* 必要组件加载区域 */

    /* 组件页面数据准备区域，包括表单，下拉选择项，数据整理操作等 */
    //提交事件回调
    const buttonEvent = val => { emit('main-action', val) }

    return {
      buttonEvent,
    }
  },
}
</script>
