import request from '@/auth/jwt/request'

/**
 * @description 名片管理 -- 名片管理列表
 * @param {Object} param params {Object} 传值参数
 */
export const callcardListApi = params => { return request('card/list', 'get', params) }

/**
 * @description 名片管理 -- 增加修改名片
 * id = 0 新增
 * @param {Object} param params {Object} 传值参数
 */
export const callcardSaveApi = data => { return request('card/save', 'post', {}, data) }

/**
 * @description 名片管理 -- 名片状态管理
 * @param {Object} param params {Object} 传值参数
 */
export const callcardStatusApi = params => { return request('card/status', 'get', params) }

/**
 * @description 名片管理 -- 名片详情
 * @param {Object} param params {Object} 传值参数
 */
export const callcardInfoApi = params => { return request('card/info', 'get', params) }

/**
 * @description 名片管理 -- 删除名片
 * { id = x }
 */
export const callcardDeleteApi = data => { return request('card/delete', 'delete', data) }

/**
 * @description 名片管理 -- 关联员工
 * { id = x }
 */
export const callcardStaffListApi = params => { return request('pull-down/staff', 'get', params) }

/**
 * @description 名片管理 -- 关联项目
 * { id = x }
 */
export const callcardProjectListApi = params => { return request('pull-down/projectSelect', 'get', params) }

 /**
 * @description 名片管理 -- 关联服务
 * { id = x }
 */
export const callcardServiceListApi = params => { return request('pull-down/doingServer', 'get', params) }

/**
 * @description 名片管理 -- 关联图册
 * { id = x }
 */
export const callcardImageListApi = params => { return request('pull-down/projectPic', 'get', params) }

/**
 * @description 名片管理 -- 关联视频
 * { id = x }
 */
export const callcardVideoListApi = params => { return request('pull-down/projectVideo', 'get', params) }

 /**
 * @description 名片管理 -- 关联产品
 * { id = x }
 */
export const callcardProductListApi = params => { return request('pull-down/projectProduct', 'get', params) }

/**
 * @description 名片管理 -- 获取项目详情
 * { id = x }
 */
export const callcardprojectInfoApi = params => { return request('project/info', 'get', params) }