<template>
  <div>
    <b-sidebar
      :id="title"
      :visible="isShowSidearActive"
      bg-variant="white"
      :sidebar-class="layConfig === 1 ? 'sidebar-lg' : 'sidebar-customer'"
      shadow
      :backdrop="backdrop ? backdrop : false"
      no-header
      right
      @hidden="resetForm"
      @change="(val) => $emit('update:is-show-sidear-active', val)"
    >
    <!-- sidebar-lg -->
    <!-- @hidden="isShowSidearActive = false" -->
      <template #default="{ hide }">
        <!-- Header -->
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5 class="mb-0">
            {{ formData[specialParam] > 0 ? '保存' : '添加' }}{{title}}
          </h5>

          <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="hide"
          />

        </div>

        <!-- BODY -->
        <!-- @reset.prevent="isShowSidearActive = false" -->
        <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
          <!-- Form -->
          <!-- section -->
          <b-form
            class="p-2"
            @submit.prevent="handleSubmit(onSubmit)"
            @reset.prevent="resetForm"
          >
            <b-row>
              <b-col
                :cols="item.cols ? item.cols : 12"
                v-for="(item,index) in blankEditParams"
                :key="index"
                v-if="!item.display">
                <validation-provider
                  #default="{ errors }"
                  :name="item.label"
                  :rules="`${item.rules ? item.rules : ''}`"
                >
                  <!-- input -->
                  <b-form-group :label="item.label" v-if="item.component === 'input'">
                    <!-- TODO: required vuexy 模板貌似不支持 -->
                    <b-form-input
                      v-model="formData[item.param]"
                      :state="errors.length > 0 ? false:null"
                      :disabled="item.disabled ? item.disabled : false"
                      :form="item.form"
                      :autofocus="item.autofocus ? item.autofocus : false"
                      :size="item.size"
                      :type="item.type ? item.type : 'text'"
                      :trim="item.trim ? item.trim : true"
                      :autocomplete="item.autocomplete ? item.autocomplete : 'on'"
                      :placeholder="item.placeholder"
                      :formatter="item.placeholder"
                      :number="item.number ? item.number : false"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>

                  <!-- radio -->
                  <b-form-group :label="item.label" v-if="item.component === 'radio'">
                    <b-form-radio-group
                      v-model="formData[item.param]"
                      :state="errors.length > 0 ? false:null"
                      :options="item.radioOptions"
                      class="col-form-label"
                      value-field="value"
                      text-field="text"
                      disabled-field="disabled"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>

                  <!-- select -->
                  <b-form-group :label="item.label" v-if="item.component === 'select'" :state="errors.length > 0 ? false:null">
                    <v-select
                      v-model="formData[item.param]"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :label="item.selectLabel ? item.selectLabel : 'name'"
                      :options="item.selectOptions"
                      :multiple="item.multiple ? item.multiple : false"
                      :reduce="val => item.valueTitle ? val[item.valueTitle]: val.id"
                    >
                      <template v-slot:no-options>没有更多信息</template>
                    </v-select>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>


                  <!-- textarea -->
                  <b-form-group :label="item.label" v-if="item.component === 'textarea'">
                    <b-form-textarea
                      v-model="formData[item.param]"
                      :state="errors.length > 0 ? false:null"
                      :disabled="item.disabled ? item.disabled : false"
                      :form="item.form"
                      :autofocus="item.autofocus ? item.autofocus : false"
                      :size="item.size"
                      :trim="item.trim ? item.trim : true"
                      :autocomplete="item.autocomplete ? item.autocomplete : 'on'"
                      :placeholder="item.placeholder"
                      :formatter="item.placeholder"
                      :number="item.number ? item.number : false"
                      :rows="item.rows ? item.rows : 3"
                      :max-rows="item.maxRows ? item.maxRows : 5"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>

                  <!-- quill -->
                  <b-form-group :label="item.label" v-if="item.component === 'quill'" :state="errors.length > 0 ? false:null">
                    <quill-editor
                      ref="quillRef"
                      v-model="formData[item.param]"
                      :options="snowOption"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>

                  <!-- ueditor -->
                  <b-form-group id="item.param" :label="item.label" v-if="item.component === 'ueditor'" :state="errors.length > 0 ? false:null">
                    <ueditor v-model="formData[item.param]" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>

                  <!-- pic -->
                  <b-form-group :label="item.label" v-if="item.component === 'pic'">
                    <div>
                      <input v-model="formData[item.param]" type="text" v-show="false" :state="errors.length > 0 ? false:null" />
                      <b-img
                        v-if="formData[item.param] && formData[item.param] !== ''"
                        :src="formData[item.param]"
                        @click="mainImageClick(item.param)"
                        class="d-inline-block mr-1 image-size"
                      />
                      <span v-else
                        @click="mainImageClick(item.param)"
                        class="d-inline-block mr-1 no-image d-flex justify-content-center align-items-center">
                        <feather-icon icon="PlusIcon" size="16" class="text-body" />
                      </span>
                    </div>
                    <small class="text-danger">{{ errors[0] }} </small>
                  </b-form-group>

                  <!-- pics :state="errors.length > 0 ? false:null" -->
                  <b-form-group :label="item.label" v-if="item.component === 'pics'">
                    <div class="d-flex flex-wrap">
                    <input :value="formData[item.param]" type="text" v-show="false" :state="errors.length > 0 ? false:null" />
                      <div
                        class="mr-1 image-size"
                        v-for="(ite, idx) in formData[item.param]" :key="idx"
                      >
                        <div
                          :id="'button' + idx"
                          @click="delSildeClick(item.param,idx)"
                          class="icon-close d-flex justify-content-center align-items-center"
                        >
                          <feather-icon icon="XIcon" />
                        </div>
                        <b-img :src="ite" class="d-inline-block mr-1 image-size" />
                      </div>

                      <span @click="slideClick(item.param)"
                        class="d-inline-block mr-1 no-image d-flex justify-content-center align-items-center">
                        <feather-icon icon="PlusIcon" size="14"  class="text-body"/>
                      </span>
                    </div>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>

                </validation-provider>
              </b-col>
            </b-row>
            <!-- Form Actions -->
            <div class="d-flex mt-2 justify-content-center">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                type="submit"
              >
                {{ formData[specialParam] ? '保存' : '添加' }}
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="button"
                variant="outline-secondary"
                @click="hide"
              >
                取消
              </b-button>
            </div>
          </b-form>
        </validation-observer>
      </template>
    </b-sidebar>

    <image-select v-model="selectImageShow" :type="selectType" :paramObject="paramObject" @set-image="getImage" />
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'

// Notification
import { ref, watch } from '@vue/composition-api'
import { quillEditor } from 'vue-quill-editor'
import { required, confirmed, integer, digits } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import Ueditor from '../Ueditor'

import { distinctArrToPropArr } from '@/libs/array'

import ImageSelect from '../ImageSelect'

export default {
  components: {
    vSelect,
    quillEditor,
    ImageSelect,
    Ueditor,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isShowSidearActive',
    event: 'update:is-show-sidear-active',
  },
  props: {
    isShowSidearActive: {
      type: Boolean,
      required: true,
    },
    title: {
      type: String,
      default: null,
      required: false,
    }, 
    blankEditParams: {
      type: Array,
      required: true,
    },
    editValue: {
      type: Object,
      required: true,
    },
    specialParam: {
      type: String,
      default: 'id',
      required: false,
    },
    layConfig: {
      type: Number,
      default: 1,
      required: false,
    },
    backdrop: {
      type: Boolean,
      default: false,
      required: false,
    }
  },
  setup(props, { emit }) {
    // toolbar工具栏的工具选项（默认展示全部）
    const toolOptions = [
      ['bold', 'italic', 'underline', 'strike'],
      ['blockquote', 'code-block'],
      [{ header: 1 }, { header: 2 }],
      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ script: 'sub' }, { script: 'super' }],
      [{ indent: '-1' }, { indent: '+1' }],
      [{ direction: 'rtl' }],
      [{ size: ['small', false, 'large', 'huge'] }],
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      [{ color: [] }, { background: [] }],
      [{ font: [] }],
      [{ align: [] }],
      ['clean'],
      // 去除视频
      // ['link', 'image']
      ['link', 'image', 'video']
    ]

    // 查询参数，自动生成界面
    const formData = ref(JSON.parse(JSON.stringify(props.editValue)))

    const resetFormData = () => {
      formData.value = JSON.parse(JSON.stringify(props.editValue))
    }

    const onSubmit = () => { 
      emit('edit-event', formData.value)
      emit('update:is-show-sidear-active', false)
    }

    const {
      refFormObserver,
      resetForm,
    } = formValidation(resetFormData)

    watch(() => props.isShowSidearActive, val => {
      val ? resetFormData() : null
    })

    /* 图片选择处理 */

    // 图片选择对话框
    const selectImageShow = ref(false)
    // 图片选择类型， 1=> 主图 2=>轮播 3=>详情
    const selectType = ref(1)
    // 超文本对象
    const quillRef = ref(null)
    // 附加属性
    const paramObject = ref(null)
    // 主图点击事件
    const mainImageClick = param => {
      paramObject.value = param
      selectType.value = 1
      selectImageShow.value = true
    }
    // 轮播图点击事件
    const slideClick = param => {
      paramObject.value = param
      selectType.value = 2
      selectImageShow.value = true
    }

    // 超文本编辑器 Quill Rich Text Editor 配置项
    // 拦截超文本的图片上传点击事件
    const handlers = {
      // 重载图片上传事件函数，切换为打开图片选择对话框
      image: function image() {
        selectType.value = 3
        selectImageShow.value = true
      }
    }
    // 超文本配置
    const snowOption = ref({
      theme: 'snow',
      placeholder: '请输入信息...',
      modules: {
        toolbar: {
          container: toolOptions,
          handlers
        },
      }
    })
    // 删除轮播图片
    const delSildeClick = (param,index) => {
      formData.value[param].splice(index, 1)
    }
    // 回传图片处理，对应type配置不同
    const getImage = (value, type, param) => {
      if (type === 1) {
        // 暂时默认获取数组第一项，后期修改为单文件
        formData.value[param] = value[0].path
      } else if (type === 2) {
        // 处理回传图片对象，抽取地址形成新数组
       formData.value[param] = formData.value[param].concat(distinctArrToPropArr(value, 'path'))
      } else {
        // 获取图片对象数组，遍历数组，插入每一张图片
        value.forEach(item => {
          // 获取当前超文本编辑框的光标位置
          const length = quillRef.value[0].quill.getSelection(true).index
          // 在光标位置插入图片对象
          quillRef.value[0].quill.insertEmbed(length, 'image', item.path)
          // 移动光标
          quillRef.value[0].quill.setSelection(length + 1)
        })
      }
    }

    return {
      snowOption,
      formData,
      onSubmit,

      refFormObserver,
      resetForm,

      required,
      confirmed,
      integer,
      digits,

      // 图片选择器
      paramObject,
      selectType,
      selectImageShow,
      getImage,
      delSildeClick,
      mainImageClick,
      slideClick,

      quillRef,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
.ql-editor {
  min-height: 300px;
}
.sidebar-customer {
  width: 60rem;
}
// 77px
.image-size {
  width: 2.714rem;
  position: relative;
  height: 2.714rem;
}
.no-image {
  width: 2.714rem;
  height: 2.714rem;
  border: 1px solid $border-color;
  color: $border-color;
  border-radius: $border-radius;
}
.icon-close {
  position: absolute;
  z-index: 1;
  width: 16px !important;
  height: 16px !important;
  right: -8px;
  top: -8px;
  border-radius: 50%;
  background-color: $warning;
  color: $border-color;
}
.dark-layout {
  .no-image {
    border: 1px solid $theme-dark-border-color !important;
  }
}
</style>
