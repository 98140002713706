import Vue from 'vue'

const baseSweetAlertModal = function
  (title, text, icon, { showCancelButton = true, confirmButtonText, cancelButtonText, confirmClass, cancelClass }) {
    return new Promise((resolve, reject) => {
      Vue.swal({
        title: title || '操作确认',
        text: text || null,
        icon: icon || 'warning',
        showCancelButton: showCancelButton,
        confirmButtonText: confirmButtonText || '确定',
        cancelButtonText: cancelButtonText || '取消',
        customClass: {
          confirmButton: confirmClass || 'btn btn-primary',
          cancelButton: cancelClass || 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
        .then(res => resolve(res) )
        .catch(err => reject(err) )
    })
}

// 处理默认参数为空不传参问题
const SweetAlertModal = (title, text, icon, opt) =>
  baseSweetAlertModal(title || '操作确认', text || null, icon || 'warning', opt || {})

export default SweetAlertModal
