<template>
  <div class="d-flex justify-content-between flex-wrap">
    <div class="d-flex align-items-center mb-0 mt-1">
      <Select :value="size" class="mr-1" @on-change="(val) => { $emit('update:page', 1), $emit('update:size', val) }">
        <Option v-for="item in pageOptions" :value="item" :key="item">{{ item }}</Option>
      </Select>
      <span v-if="showPageTitle" class="text-nowrap mr-1"> 条/页 </span>
      <span v-if="showTotal" class="text-nowrap"> 共计 {{ total }} 条记录 </span>
    </div>
    <div class="d-flex align-items-center">
      <b-pagination :value="page" :total-rows="total" :per-page="size" first-number last-number align="right" prev-class="prev-item" next-class="next-item" class="mt-1 mb-0" @change="(val) => $emit('update:page', val)"
      >
        <template #prev-text>
          <feather-icon icon="ChevronLeftIcon" size="18" />
        </template>
        <template #next-text>
          <feather-icon icon="ChevronRightIcon" size="18" />
        </template>
      </b-pagination>
      <div class="d-flex align-items-center mb-0 mt-1 ml-1" v-if="showJump">
        <span class="text-nowrap">跳至</span>
        <Input @on-enter="pageToChange"
          v-model="pageTo" type="number" number class="mx-1" contenteditable="true" style="width: 4rem;"/>
        <span class="text-nowrap">页</span>
      </div>
    </div>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import { ref, watch } from '@vue/composition-api'

export default {
  components: {
    vSelect,
  },
  props: {
    page: {
      type: Number,
      required: true,
    },
    size: {
      type: Number,
      required: true,
    },
    total: {
      type: Number,
      required: false,
    },
    sizeOptions: {
      type: Array,
      required: false,
    },
    showTotal: {
      type: [Boolean, null],
      default: true,
      required: false,
    },
    showJump: {
      type: [Boolean, null],
      default: true,
      required: false,
    },
    showPageTitle: {
      type: [Boolean, null],
      default: true,
      required: false,
    },
  },

  setup(props, { emit }) {
    /* 必要组件加载区域 */

    /* 组件页面数据准备区域，包括表单，下拉选择项，数据整理操作等 */

    // 分页下拉项 默认
    const pageOptions = ref([10, 25, 50])

    // 页码跳转参数
    const pageTo = ref(null)
    // 页码直接填写跳转控制函数
    const pageToChange = () => {
      if (!pageTo.value) return
      const count = Math.ceil(props.total / props.size)
      if (pageTo.value < 1) {
        pageTo.value = 1
      } else if (pageTo.value > count) {
        pageTo.value = count
      } else {
        pageTo.value = parseInt(pageTo.value, 0)
      }

      // 指定跳转页面数
      emit('update:page', pageTo.value)
    }

    // 是否有配置参数 size options 给定了参数，替换默认
    watch(() => props.sizeOptions, val => {
      if (val && val.length) { pageOptions.value = props.sizeOptions }
    })

    return {
      pageTo,
      pageOptions,
      pageToChange,
    }
  },
}
</script>