<template>
  <div>
    <vue-ueditor-wrap
      :value="content"
      :config="myConfig"
      @beforeInit="addVueDialogButton"
      @ready="onEditorReady"
      @input="(val) => $emit('update:content', val)"
    />

    <!-- 图片选择器 -->
    <image-select v-model="selectImageShow" :type="selectType" @set-image="getImage" />
  </div>
</template>

<script>
import { ref, onMounted, computed, watch } from '@vue/composition-api'
import VueUeditorWrap from 'vue-ueditor-wrap'
import ImageSelect from '@/comps/ImageSelect'
import { $urlConfig } from '@themeConfig'

export default {
  components: {
    VueUeditorWrap,
    ImageSelect,
  },

  model: {
    prop: 'content',
    event: 'update:content',
  },

  props: {
    content: {
      required: true,
    },
  },
 
  setup(props, { root, emit }) {
    /* 必要组件加载区域 */

    // editor 对象
    const refEditorObserver = ref(null)
    // 组件渲染成功后回调拿到DOM对象
    const onEditorReady = editor => {refEditorObserver.value = editor }

    /* 组件页面数据准备区域，包括表单，下拉选择项，数据整理操作等 */
    
    // 图片选择器控制
    const selectImageShow = ref(false)
    // 图片选择类型， 单图,多图
    const selectType = ref(2)
    // 处理图片插入
    const getImage = (value, type, param) => {
      let str = ''
      value.forEach(el => { str += '<img src="' + el.path + '">' })
      refEditorObserver.value.focus()
      refEditorObserver.value.execCommand('inserthtml', str)
    }

    // 自定义图片选择，视频上传 toolbar
    const addVueDialogButton = editorId => {
      window.UE.registerUI(
        'image-button' + editorId,
        (editor, uiName) => {
          const btn = new window.UE.ui.Button({
            name: uiName,
            title: '上传图片',
            cssRules: 'background-image: url(../../../assets/images/icons.png);background-position: -726px -77px;',
            onclick: () => {
              selectImageShow.value = true
            },
          })

          return btn
        },
        37,
        editorId
      )

      window.UE.registerUI(
        'video-button' + editorId,
        (editor, uiName) => {
          const btn = new window.UE.ui.Button({
            name: uiName,
            title: '上传视频',
            cssRules: 'background-image: url(../../../assets/images/icons.png);background-position: -320px -20px;',
            onclick() { selectImageShow.value = true },
          })

          return btn
        },
        38,
        editorId
      )
    }
    
    const env = process.env.NODE_ENV

    //编辑器配置
    const myConfig = ref({
      // 编辑器不自动被内容撑高
      autoHeightEnabled: true,
      // 初始容器高度
      initialFrameHeight: 320,
      // 初始容器宽度
      // initialFrameWidth: 766,
      // 初始容器宽度
      initialFrameWidth: 756,
      // 初始容器宽度
      // initialFrameWidth: '100%',
      // public 目录下目录
      // UEDITOR_HOME_URL: env === 'development' ? '/UEditor/' : '/admin/UEditor/',
      UEDITOR_HOME_URL: '/UEditor/',
      // 服务器端地址
      serverUrl:env === 'development' ? '' : $urlConfig.baseUrl,
      zIndex: 1036
    })

    /* 接口调用区域 */

    /* 组件内部逻辑处理，生命周期问题处理区域 */

    /* 分割线 */

    return {
      refEditorObserver,
      myConfig,
      addVueDialogButton,
      onEditorReady,

      getImage,
      selectType,
      selectImageShow,
    }
  },
}
</script>