<template>
<div>
  <div
    v-if="menu.length <= numFlat"
    class="d-flex align-items-center text-nowrap px-1" 
    :class="menu.length === 1 ? 'justify-content-center':'justify-content-between'"
  >
    <!-- 平铺菜单 -->
    <section v-for="(data,index) in menu" :key="index">
      <b-button
        :v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        :id="`table-action-${rowName}-${index}-button`"
        variant="flat-primary"
        class="btn-icon vxe-table-button-padding"
        size="sm"
        @click="$emit('click-event', data.clickType, extraData)"
      >
        <!-- <feather-icon
          :icon="data.icon"
          class="cursor-pointer text-body"
          size="16"
        /> -->
        {{ data.text }}
      </b-button>
      <b-tooltip
        v-if="data.showTip"
        :title="data.tips || data.text"
        class="cursor-pointer"
        :target="`table-action-${index}-button`"
      />
    </section>
  </div>

  <div class="text-nowrap d-flex justify-content-between align-items-center px-1" v-else>
    <!-- 平铺菜单 -->
    <section v-for="(data,index) in menu.slice(0, numFlat - 1)" :key="index">
      <b-button
        :v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        :id="`table-action-${rowName}-${index}-button`"
        variant="flat-primary"
        class="btn-icon vxe-table-button-padding"
        size="sm"
        @click="$emit('click-event', data.clickType, extraData)"
      >
        <!-- <feather-icon
          :icon="data.icon"
          class="cursor-pointer text-body"
          size="16"
        /> -->
        {{ data.text }}
      </b-button>
      <b-tooltip
        v-if="data.showTip"
        :title="data.tips || data.text"
        class="cursor-pointer"
        :target="`table-action-${index}-button`"
      />
    </section>

    <!-- 下拉菜单 Dropdown -->
    <section>
      <Dropdown
        trigger="click" transfer placement="bottom"
        @on-click="val => $emit('click-event', val, extraData)"
      >
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="flat-primary"
          class="btn-icon"
          size="sm"
        >
          <!-- <feather-icon icon="MoreVerticalIcon" size="16" class="text-body" /> -->
          更多
        </b-button>
        <DropdownMenu slot="list">
            <DropdownItem
              v-for="(data,index) in menu.slice(numFlat - 1)" :key="index"
              :name="data.clickType"
            >
              <div class="d-flex align-items-center">
                <feather-icon :icon="data.icon" size="16"/>
                <span class="ml-75">{{ data.text }}</span>
              </div>
            </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </section>

  </div>
</div>

</template>

<script>
import { ref, watch } from '@vue/composition-api'
import Ripple from 'vue-ripple-directive'

export default {
  directives: {
    Ripple,
  },
  props: {
    rowName: {
      type: [Number, String],
      required: true,
    },
    menu: {
      type: Array,
      required: true,
    },
    numFlat: {
      type: [Number, null],
      required: false,
      default: 3,
    },
    extraData: {
      type: [Number, Object, Array, String, Boolean, null],
      required: false,
    }
  },

  setup(props, { emit }) {
    /* 必要组件加载区域 */

    /* 组件页面数据准备区域，包括表单，下拉选择项，数据整理操作等 */
    const menuFlat = ref([])
    // 下拉菜单
    const menuList = ref([])

    //提交数据回父组件
    // const setValue = (val, param, index) => emit('update:params', val, param, index)

    const setMenu = val => {
      // 为了保持菜单呈现一致性，过滤对应 disabled
      if (val.length >= props.numFlat) { 
        menuFlat.value = val.slice(0, props.numFlat - 1)
        menuList.value = val.slice(props.numFlat - 1)
      } else {
        menuFlat.value = val
        menuList.value = []
      }
    }

    // 处理平铺菜单的排版方式
    watch(() => props.menu, val => { setMenu(val) })

    return {
      menuList,
      menuFlat,
    }
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-sweetalert.scss';

.ivu-dropdown-rel {
  line-height: 16px;
}

.ivu-dropdown-menu {
  // min-width: 8rem;
  margin-bottom: 0;

  box-shadow: $dropdown-box-shadow;
  border: none;
  border-radius: $dropdown-border-radius;
  padding: 0.5rem 0;

  .ivu-dropdown-item {
    // padding: 0.65rem 1.28rem;
    // background-color: transparent;
    border: 0;

    color: $dropdown-link-color;
    padding: $dropdown-item-padding-y $dropdown-item-padding-x;
  }

  .ivu-dropdown-item:hover {
    color: $dropdown-link-hover-color;
    background-color: $dropdown-link-hover-bg;
  }

  .ivu-dropdown-item:focus {
    color: $dropdown-link-hover-color;
    background-color: $dropdown-link-hover-bg;
  }
}

body.dark-layout {
  .ivu-dropdown-menu {
    background-color: $theme-dark-body-bg;
    .ivu-dropdown-item {
      color: $theme-dark-body-color;
    }
  }
}
</style>