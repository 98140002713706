import request from '@/auth/jwt/request'

/**
 * @description 文件管理 -- 获取文件管理的子分类
 * @param {Object} param params {Object} 传值参数
 */
export const fileListApi = params => { return request('file/list', 'get', params) }

/**
 * @description 文件管理 -- 修改文件名称
 * id = 0 新增
 * @param {Object} param params {Object} 传值参数
 */
export const fileEditNameApi = data => { return request('file/editFileName', 'post', {}, data) }

/**
 * @description 文件管理 -- 修改文件分类
 * id = 0 新增
 * @param {Object} param params {Object} 传值参数
 */
 export const fileEditCateApi = data => { return request('file/editCategory', 'put', {}, data) }

/**
 * @description 文件管理 -- 删除文件管理
 * { id = x }
 */
export const fileDeleteApi = data => { return request('file/delete', 'delete', {}, data) }