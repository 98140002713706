<template>
  <b-card no-body class="mb-1">
    <b-card-body class="pb-0 pt-1">
    <!-- 查询操作 -->
    <b-row>
      <!-- TODO: 未扩充 vue-select 属性支持 -->
      <b-col cols="auto" class="flex-grow-1 mb-1" v-if="showSingleSelect && singleSelect">
        <b-form-group label-cols="auto" :label="singleSelect.label" style="margin-bottom: 0;">
          <v-select
            :value="singleSelect.value"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            class="search-filter-select"
            :label="singleSelect.selectLabel ? singleSelect.selectLabel : 'label'"
            :options="singleSelect.selectOptions"

            :reduce="val => singleSelect.valueTitle ? val[singleSelect.valueTitle]: val.id"
            @input="(val) => setValue(val, singleSelect.param, singleSelect.arrayIndex)"
          >
            <template v-slot:no-options>没有更多信息</template>
          </v-select>
        </b-form-group>
      </b-col>

      <b-col cols="auto" class="flex-grow-1 mb-1" v-if="showSingleInput && singleInput">
        <b-form-group label-cols="auto" :label="singleInput.label" style="margin-bottom: 0;">
          <!-- TODO: required vuexy 模板貌似不支持 -->
          <b-form-input
            :disabled="singleInput.disabled ? singleInput.disabled : false"
            :form="singleInput.form"
            :autofocus="singleInput.autofocus ? singleInput.autofocus : false"
            :size="singleInput.size"
            :state="singleInput.state ? singleInput.state : null"

            :value="singleInput.value"
            :type="singleInput.type ? singleInput.type : 'text'"
            :trim="singleInput.trim ? singleInput.trim : true"
            
            :autocomplete="singleInput.autocomplete ? singleInput.autocomplete : 'on'"
            :placeholder="singleInput.placeholder"
            :formatter="singleInput.placeholder"
            :number="singleInput.number ? singleInput.number : false"
            :debounce="singleInput.debounce ? singleInput.debounce : '300'"

            @update="(val) => setValue(val, singleInput.param, singleInput.arrayIndex)"
          />
        </b-form-group>
      </b-col>

      <b-col cols="auto" class="flex-grow-1 mb-1" v-if="showMultipleInput && arrayInput.length">
        <b-input-group>
          <b-input-group-prepend>
            <b-dropdown
              :text="arrayInputObject.label"
              variant="outline-primary"
            >
              <b-dropdown-item
                v-for="(item, index) in arrayInput"
                :key="index"
                @click="arrayInputItemEvent(index)"
              >
                {{ item.label }}
              </b-dropdown-item>
            </b-dropdown>
          </b-input-group-prepend>
          <b-form-input
            :disabled="arrayInputObject.disabled ? arrayInputObject.disabled : false"
            :form="arrayInputObject.form"
            :autofocus="arrayInputObject.autofocus ? arrayInputObject.autofocus : false"
            :size="arrayInputObject.size"
            :state="arrayInputObject.state ? arrayInputObject.state : null"

            :value="arrayInputObject.value"
            :type="arrayInputObject.type ? arrayInputObject.type : 'text'"
            :trim="arrayInputObject.trim ? arrayInputObject.trim : true"
            
            :autocomplete="arrayInputObject.autocomplete ? arrayInputObject.autocomplete : 'on'"
            :placeholder="arrayInputObject.placeholder"
            :formatter="arrayInputObject.placeholder"
            :number="arrayInputObject.number ? arrayInputObject.number : false"
            :debounce="arrayInputObject.debounce ? arrayInputObject.debounce : '300'"

            @update="(val) => setValue(val, arrayInputObject.param, arrayInputObject.arrayIndex)"
          />
        </b-input-group>
      </b-col>

      <!-- toggle-class='dropdown-width' -->
      <b-col
        cols="auto" v-if="showMultipleSelect && arraySelect.length"
        class="d-flex flex-grow-1 align-items-center justify-content-start mb-1">
          <b-dropdown
            :text="arraySelectObject.label"
            variant="outline-primary"
          >
            <b-dropdown-item
              v-for="(item, index) in arraySelect"
              :key="index"
              @click="arraySelectItemEvent(index)"
            >
              {{ item.label }}
            </b-dropdown-item>
          </b-dropdown>
          <!-- TODO: 未扩充 vue-select 属性支持 -->
          <v-select
            :value="arraySelectObject.value"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            class="search-filter-select d-inline-block ml-50 flex-grow-1"
            :label="arraySelectObject.selectLabel ? arraySelectObject.selectLabel : 'label'"
            :options="arraySelectObject.selectOptions"

            :reduce="val => arraySelectObject.valueTitle ? val[arraySelectObject.valueTitle]: val.id"
            @input="(val) => setValue(val, arraySelectObject.param, arraySelectObject.arrayIndex)"
          >
            <template v-slot:no-options>没有更多信息</template>
          </v-select>
        <!-- </b-input-group> -->
        <!-- </b-form-group> -->
      </b-col>

      <b-col cols="auto" class="mb-1 flex-grow-1 d-flex flex-row-reverse justify-content-between">
        <section v-if="useAddButton">
          <b-button
              variant="primary"
              @click="buttonEvent('add')"
            >
            <feather-icon icon="PlusIcon" class="mr-50" />
            <span>新建{{ addTitle }}</span>
          </b-button>
        </section>
        <section class="d-flex flex-row-reverse">
          <b-button
            v-if="useMoreButton"
            class="ml-50"
            variant="outline-primary"
            @click="buttonEvent('more')"
          >
            <feather-icon icon="MoreVerticalIcon" class="mr-50" />
            <span>更多</span>
          </b-button>
          <b-button
            v-if="useResetButton"
            class="ml-50"
            variant="outline-primary"
            @click="buttonEvent('reset')"
          >
            <feather-icon icon="RotateCcwIcon" class="mr-50" />
            <span>重置</span>
          </b-button>
          <b-button
              v-if="useSelectButton"
              variant="primary"
              @click="buttonEvent('search')"
            >
            <feather-icon icon="SearchIcon" class="mr-50" />
            <span>查询</span>
          </b-button>
        </section>
      </b-col>
    </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'

export default {
  directives: {
    Ripple,
  },
  components: {
    vSelect,
  },
  props: {
    searchParams: {
      type: Array,
      required: true,
    },
    showSingleInput: {
      type: [Boolean, null],
      default: true,
      required: false,
    },
    showSingleSelect: {
      type: [Boolean, null],
      default: true,
      required: false,
    },
    showMultipleInput: {
      type: [Boolean, null],
      default: false,
      required: false,
    },
    showMultipleSelect: {
      type: [Boolean, null],
      default: false,
      required: false,
    },
    useSelectButton: {
      type: [Boolean, null],
      default: true,
      required: false,
    },
    useMoreButton: {
      type: [Boolean, null],
      default: false,
      required: false,
    },
    useResetButton: {
      type: [Boolean, null],
      default: true,
      required: false,
    },
    useAddButton: {
      type: [Boolean, null],
      default: false,
      required: false,
    },
    addTitle: {
      type: [String, null],
      default: null,
      required: false,
    },
  },

  setup(props, { emit }) {
    /* 必要组件加载区域 */

    /* 组件页面数据准备区域，包括表单，下拉选择项，数据整理操作等 */

    // 单文本 参数
    const singleInput = ref(null)
    // 单下拉 参数
    const singleSelect = ref(null)
    // 文本数组
    const arrayInput = ref([])
    // 下拉数组
    const arraySelect = ref([])

    // 默认的数组文本下拉参数对象
    const arrayInputObject = ref(null)
    // 默认的数组下拉参数对象
    const arraySelectObject = ref(null)

    // 参数整理成对应格式
    const changeParams = () => {
      arrayInput.value = [], arraySelect.value = []
      // 处理参数变化
      props.searchParams.forEach((el, index) => {
        // 获取文本类参数数组
        if (el.component === 'input') {
          el.arrayIndex = index // 设置原参数的对应下标
          arrayInput.value.push(el) // 加入文本数组
        }
        // 获取下拉参数数组
        if (el.component === 'select') {
          el.arrayIndex = index // 设置原参数的对应下标
          arraySelect.value.push(el) // 加入下拉数组
        }
      })

      // 判断是否需要输出 单文本参数 如果需要，设置单文本信息
      singleInput.value = props.showSingleInput ? arrayInput.value.shift() : null
      // 判断是否需要输出 单下拉参数 如果需要，设置单下拉信息
      singleSelect.value = props.showSingleSelect ? arraySelect.value.shift() : null

      // 处理默认选择的单文本
      arrayInputObject.value = arrayInput.value.length ? arrayInput.value[0]: null
      // 处理默认选择的select
      arraySelectObject.value = arraySelect.value.length ? arraySelect.value[0]: null
    }

    changeParams()

    // 处理 input 数组点击切换
    const arrayInputItemEvent = index => { arrayInputObject.value = arrayInput.value[index] }

    // 处理 select 数组点击切换
    const arraySelectItemEvent = index => { arraySelectObject.value = arraySelect.value[index] }

    /* 事件回调 */

    //提交参数数据回父组件
    const setValue = (val, param, index) => emit('update:searchParams', val, param, index)

    //提交事件回调
    const buttonEvent = val => emit('button-event', val)

    // 更新组件参数
    watch(() => props.searchParams, () => { changeParams() })

    return {
      setValue,

      singleInput,
      arrayInput,
      arraySelect,
      singleSelect,

      arrayInputObject,
      arrayInputItemEvent,

      arraySelectObject,
      arraySelectItemEvent,

      buttonEvent,
    }
  },
}
</script>

<style lang="scss" scoped>
.search-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.dropdown-width {
  width: 120px;
}
</style>
