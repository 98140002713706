import Vue from 'vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

const baseNotice = (title, icon, text, variant) => {
  Vue.$toast({
    component: ToastificationContent,
    props: { title, icon, text, variant },
  })
}

// 处理默认参数为空不传参问题
const msgNotice = (title, text, icon, variant) => 
  baseNotice(title || '异常告警', icon || 'AlertTriangleIcon', text, variant || 'danger')

export default msgNotice
