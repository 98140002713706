import MsgNotice from '@/comps/MsgNotice'
import useJwt from '@/auth/jwt//useJwt'
// eslint-disable-next-line object-curly-newline
import { ref, nextTick, computed
// , computed, watch, onMounted
} from '@vue/composition-api'
// import store from '@/store'
import { $fileUploadConfig } from '@themeConfig'

export default function fileUpload(fileList) {
  // 上传接口 header 参数
  const header = ref({})
  header.value.Authorization = `${useJwt.jwtConfig.tokenType} ${useJwt.getToken()}`

  // 上传接口地址参数
  const fileUrl = ref($fileUploadConfig.fileUrlMutiple)
  // 接口上传参数
  const uploadData = ref({})
  // 文件上传格式后缀名
  const format = ref({
    doc: ['doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx', 'pdf', 'txt', 'md'],
    image: ['jpg', 'jpeg', 'jpe', 'jpz', 'png', 'png', 'gif'],
    video: ['mp3', 'wma', 'wav'],
    audio: ['mp4', 'avi', 'mov', 'wmv', 'mpg', 'mpeg'],
  })
  // 文件上传类型
  const accept = ref({
    doc: '.doc,.docx,.xls,.xlsx,.ppt,.pptx,.pdf',
    image: 'image/*',
    video: 'video/*',
    audio: 'audio/*',
  })
  // 文件上传大小控制
  const maxSize = ref(5120)
  const maxSizeTxt = computed(() => maxSize.value / 1024 + 'MB')

  // 是否多文件上传
  const isMultiple = ref(true)

  // 文件上传成功时回调 返回字段为 response, file, fileList
  const handleSuccess = (_, file) => {
    MsgNotice('上传文件成功', '文件  ' + file.name + ' 上传成功!', 'CheckIcon', 'success')
    fileList()
  }

  // 文件格式验证失败时的钩子，返回字段为 file, fileList
  const handleFormatError = file => {
    MsgNotice('格式选择错误', '文件  ' + file.name + ' 的格式暂时不支持,请联系软件开发者', 'BellIcon', 'warning')
  }

  // 文件超出指定大小限制时的钩子，返回字段为 file, fileList
  const handleMaxSize = file => {
    MsgNotice('文件超过限制', '文件  ' + file.name + ' 太大了, 超过了' + maxSizeTxt.value, 'BellIcon', 'danger')
  }

  // 上传文件之前的钩子，参数为上传的文件，若返回 false 或者 Promise 则停止上传
  const handleBeforeUpload = () => {
    const promise = new Promise(resolve => { nextTick(() => { resolve(true) }) })
    return promise
  }

  return {
    header,
    fileUrl,
    uploadData,
    format,
    accept,
    maxSize,
    isMultiple,

    handleSuccess,
    handleFormatError,
    handleMaxSize,
    handleBeforeUpload,
  }
}
